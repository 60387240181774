// UnisaveLanding.js

import React from 'react';

const UnisaveLanding = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-blue-900 text-white font-sans">
      {/* Top Navigation */}
      <div className="flex justify-between items-center w-full max-w-screen-xl p-5 mb-20 ">
        <div className="text-2xl font-bold">
          <span className="text-white">Uni</span>
          <span className="text-red-500">save</span>
        </div>
        {/* <button className="px-4 py-2 text-white border border-red-500 rounded-full bg-red-500 hover:text-white transition">
          Follow Unisave on X
        </button> */}
        <a href="https://x.com/unisave_io"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-600 text-white font-semibold py-2 px-5 sm:px-10 rounded-full hover:bg-red-500 transition-all duration-300"
            >              
              Follow Unisave on X
            </a>
      </div>
      
      {/* Main Content */}
      <div className="text-center mt-12">
        <h1 className="text-5xl font-semibold">
          Tranform 
          <span className="text-red-500"> $5T Saving Market</span>
          <br />
          with
          <span className="text-red-500"> Refundable NFT.</span>
        </h1>
        <div className='mt-10'>
        <a  href='https://tally.so/r/nGJ6Be'
            target="_blank"
            rel="noopener noreferrer"
          className="mt-20 px-6 py-3 text-blue-900 bg-white font-semibold rounded-full hover:bg-gray-100 transition">
          Minting soon with Limited Supply. Whitelist Register Here.
        </a>
        </div>
      </div>
      
      {/* Footer */}
      <div className="mt-12 flex justify-center space-x-6">
        <div className="h-4 w-4 rounded-full bg-white"></div>
        <div className="h-4 w-4 rounded-full bg-white"></div>
        <div className="h-4 w-4 rounded-full bg-white"></div>
      </div>
    </div>
  );
};

export default UnisaveLanding;
