import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      {/* Main Wrapper */}
      <Navbar/>
      <div className="flex flex-col bg-white text-gray-900">
        {/* Hero Section */}                
        <section className="flex flex-col items-center justify-center w-full text-white py-10 h-[calc(100vh-64px)]">
            <div className="container mx-auto px-4 flex flex-col items-center justify-center h-full">
              <div className="flex flex-col items-center">
                {/* <h2 className="text-xl sm:text-3xl md:text-4xl text-[#003b79] font-bold">Reclaim our billions from big banks!</h2> */}
                <div className="">
                             
                  <img className="mx-auto max-h-[50vh] w-auto" src="./uncleSamTransparent.png" alt="Hero Image" />
                </div>
                
                <p className="text-base sm:text-lg md:text-4xl text-[#003b79] mb-6 md:mb-10 max-w-2xl text-center">
                  {/* I wants to <strong className="text-red-600">give back billions</strong> in interest to <strong className="text-red-600">global savers</strong> through secure, fun, and rewarding games. */}
                  {/* For too long, the average saver has been squeezed by the big banks and financial elites. It's time to take back billions in interest from outdated banks to global savers. */}
                  I want <strong className='text-red-500'>you</strong> <br/>to receive the <strong className='text-red-500'>reward</strong> <br/>you deserve from your <strong className='text-red-500'>savings</strong>.
                  {/* I want <strong className='text-red-500'>you</strong> to enjoy <br/>a <strong className='text-red-500'>secure, fun, and rewarding</strong> savings experience.                   */}
                </p>
                {/* <a
                  href="https://app.unisave.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-600 text-white font-semibold py-2 px-6 sm:px-8 md:py-3 md:px-10 rounded hover:bg-red-500 transition-all duration-300"
                >
                  Launch Unisave App.
                </a> */}
                
              </div>
            </div>
          </section>

        {/* No Loss, High Reward Section */}
         <section id="no-loss" className="bg-gray-50 py-1 sm:py-20 text-[#003b79]">
          <div className="container flex flex-col items-center text-center px-5">
            
            {/* <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-10">Start saving money onchain. The Jackpots are yours to claim.</h2>             */}
            {/* <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-10">Save together, win weekly prizes, have fun!</h2>             */}
            {/* <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8">
              Start by saving $100 into a secure Unisave and unlock countless chances to win $1,000,000. 
              <br/>The more Unisaves you hold, the greater your chances of winning big.
            </p> */}
            {/* <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-10">Transforming multi-trillion-dollar saving market.</h2> */}
            {/* <p className="text-base w-1/2 text-center sm:text-lg md:text-xl mb-6 sm:mb-8">Unisave starts from a simple question: with the Fed Funds Rate over 5%, why are 5 trillion dollars in U.S. bank saving accounts receiving close to 0%? <br/><br/>For too long, big banks have quietly squeezed savers worldwide. This hidden pressure combined with new crypto technology creates a massive opportunity in transforming the multi-trillion-dollar saving market. <br/><br/>But how do we encourage millions of people worldwide to work together to reclaim savers interest?</p> */}

            {/* <a href="https://app.unisave.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-600 text-white font-semibold py-3 px-8 sm:px-10 rounded hover:bg-red-500 transition-all duration-300"
            >              
              Start Saving 
            </a> */}
          </div>
        </section>   
       
        {/* Save To Earn Section */}
        <section id="save-to-earn" className="py-10 sm:py-16 bg-gray-100 ">
          <div className="container flex flex-col items-center text-center px-5 text-[#003b79]">
            
            {/* <div className="flex flex-col md:flex-row gap-6 md:gap-8 justify-center items-center">
              <div className="flex-1 w-full rounded-lg border-2 p-6 md:p-10 border-[#003b79]  transition duration-300">
                <h3 className="text-base sm:text-lg md:text-xl">Step 1: Save your money into a secure Unisave which has unique numbers.</h3>
              </div>
              <div className="flex-1 w-full rounded-lg border-2 p-6 md:p-10 border-[#003b79] transition duration-300">
                <h3 className="text-base sm:text-lg md:text-xl">Step 2: Having fun waiting for the draw on the weekend.</h3>
              </div>
              <div className="flex-1 w-full rounded-lg border-2 p-6 md:p-10 border-[#003b79] transition duration-300">
                <h3 className="text-base sm:text-lg md:text-xl">Step 3: Check your winnings and enjoy your rewards.</h3>
              </div>
            </div> */}
            {/* <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-10">Introduce Unisave</h2> */}
            {/* <p className="text-base w-1/2 text-center sm:text-lg md:text-xl mb-6 sm:mb-8">Enabled by new crypto technology, Unisave coordinates global savers through smart incentives, secure saving, exciting experiences, and life changing prizes every week.<br/><br/>Unisave transforms saving into a secure, fun and rewarding game, making it easy and enjoyable for everyone to participate and benefit.</p> */}
            <div className="mt-8 sm:mt-12">
              <a href="https://x.com/unisave_io"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-red-600 text-white font-semibold py-3 px-8 sm:px-10 rounded hover:bg-red-500 transition-all duration-300"
              >
                Follow Unisave on X
              </a>
            </div>
          </div>
        </section>

        

        {/* Footer */}
        <footer className="bg-gray-200 text-black py-8 sm:py-10">
          <div className="container mx-auto text-center text-[#003b79]">
            <p>&copy; 2024 Unisave. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
